$(function () {
	window.do_notification = function (message, type) {
                if (typeof type === 'undefined')
                        msg_type = 'success';
                else
                        msg_type = 'danger';

        $.notify ({
                        icon: "notifications",
                        message: message,
                        
                },{
                        z_index: 10000,
                        type: msg_type,
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
    };

	$('body').on ('click', '.NotLoggedIn', function (e) {
		e.preventDefault ();
		var	href = $(this).attr ('href');

		if (href) {
			$('#redirect_url').val (href);
		}

		$('.modal.in').modal ('hide');
		$('#signin-modal').modal ('show');
	});

	$('.do_actual_logout').click (function (e) {
		$('#logout_form').trigger ('submit');
	});

	$('body').on ('change', '.actual_cropbox', function (e) {
		var	a5_wrapper = $(this).closest ('.a5_crop_element');

		console.log ('A5 WRAPER:');
		console.log (a5_wrapper);

		var	file = $(this)[0].files [0];

		blob = URL.createObjectURL (file);

		var img = a5_wrapper.find ('.cropbox_img');
		var	base64_input = a5_wrapper.find ('.base64_input');

		img.attr ('src', blob);

		setTimeout (function () {
			console.log ('is complete: ');

			if (img [0].complete) {
				var	ac = a5_wrapper.find ('.actual_cropbox');

				var	cropbox_height = ac.attr ('data-cropbox-height');
				var	cropbox_width = ac.attr ('data-cropbox-width');

				console.log ('cropbox h w: ' + cropbox_height + ' ' + cropbox_width);

				if (img[0].naturalHeight < cropbox_height ||
					img[0].naturalWidth < cropbox_width) {
					img.attr ('src', '');
					do_notification ('Minimum dimensions: ' + cropbox_width + 'x' + cropbox_height + 'px', 1);
					return (false);
				}

				console.log ('Enabling cropbox..');
				img.cropbox ({ width: cropbox_width, height: cropbox_height })
				.on ('cropbox', function (event, results, cropped_image) {
					base64_input.val (cropped_image.getDataURL ());
				});
			}
		}, 1000);
	});

});
/**
 * Created by Lenovo on 27.04.2017.
 */
$(document).ready(function() {

	//Change Search Form Show Toggle
	$('#change_search').on('click',function (e) {
		e.preventDefault();
		$('#search_form').slideToggle();
		console.log($(this).find('.ion'));
		$(this).find('.ion').toggleClass('ion-chevron-up');
	});

	//Load Gallery on Preview Image click
	$('.bb-load-gallery:not(activated)').on('click',function(e){
		e.preventDefault();
		$(this)
			.addClass('activated')
			.siblings()
			.find('.bb-hotel-gallery:not(.initialized)') //Only for noninitialized gallery
			.addClass('initialized') //Toggle gallery initialization
			.magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled: true
			},
			closeOnContentClick: true,
			closeBtnInside: true,
			mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
			image: {
				verticalFit: true
			},
			zoom: {
				enabled: false,
				duration: 300 // don't foget to change the duration also in CSS
			}
		}) //Initialize gallery
			.slideDown(); //Show gallery

		//Show first tab
		$(this).parents('.bb-hotel-item')
			.find('.nav-tabs li a')
			.first().trigger('click');
	});

	//Load Gallery on nav tab click
	$('.nav-tabs li a').on('click', function(){
		$(this).parents('.bb-hotel-item')
			.find('.bb-hotel-gallery:not(.initialized)')
			.addClass('initialized')
			.magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled: true
			},
			closeOnContentClick: true,
			closeBtnInside: true,
			mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
			image: {
				verticalFit: true
			},
			zoom: {
				enabled: false,
				duration: 300 // don't foget to change the duration also in CSS
			}
		})
			.slideDown();
		$(this).parents('.bb-hotel-tile').find('.bb-load-gallery').addClass('activated');

	});

});
