$(document).ready (function () {
	$('#do-banner').lightSlider({
		item: 1,
		controls: false,
		pager: true,
		enableDrag: true,
		auto: true,
		speed: 1000,
		pause: 10000,
		loop: true,
		slideMargin: 0,
		onSliderLoad: function() {
			setTimeout(function(){
				$('.do-banner-loader').fadeOut();
			},1200);
		}
	});

	$('#discover-slider').lightSlider({
		item: 4,
		controls: true,
		pager: false,
		slideMargin: 20,
		enableDrag: false
	});

	$('.display_hotel_microsite').click (function (e) {
		var	hotel_id = $(this).attr ('data-hotel-id');

		var	check_in_date = $(this).attr ('data-check-in-date') || '';
		var	check_out_date = $(this).attr ('data-check-out-date') || '';
		var	nr_children = $(this).attr ('data-nr-children') || '';
		var	nr_adults = $(this).attr ('data-nr-adults') || '';

		var	full_url = '/ajax/render_hotel_microsite?hotel_id=' + hotel_id + '&check_in_date=' + check_in_date + '&check_out_date=' + check_out_date + '&nr_children=' + nr_children + '&nr_adults=' + nr_adults;

		e.preventDefault ();

		$.get (full_url, function (html) {
			$('#hotel_microsite_modal').html (html);

			$("#hotel_gallery").unitegallery ({
				gallery_background_color: "#fff",
				theme_enable_text_panel: false,

				theme_enable_fullscreen_button: true,
				theme_enable_play_button: false,
				theme_enable_hidepanel_button: false,

				gallery_width:580,
				gallery_height:400,

				slider_enable_zoom_panel: false,
				slider_videoplay_button_type: "round",
				thumb_width:175,
				thumb_height:100,

			});

			var	tomorrow = moment ().add (1, 'days');
			var	day_after = moment ().add (2, 'days');

			/* FR: Make sure we open the next day on the Check out date, once the Check in date has been changed. */

			$ ('.check_in_datepicker').datetimepicker ({
				format: 'DD/MM/YYYY',
				minDate: tomorrow,
				defaultDate: tomorrow,
			}).on ('dp.change', function (e) {
				var	check_out = $(this).closest ('.avail_form').find ('.check_out_datepicker');
				var next_day = e.date.add (1, 'days');
				check_out.data ('DateTimePicker').minDate (next_day).date (next_day);
			});

			$ ('.check_out_datepicker').datetimepicker ({
				format: 'DD/MM/YYYY',
				minDate: day_after,
				defaultDate: day_after,
			});


			$('#hotel_microsite_modal').modal ('show');
		});
	});

	/* FR: Check availability for:
	 * 1) Room
	 * 2) Package
	 * 3) Treatment
	 * 4) SPA
	 */

	$('body').on ('click', '.MicrositeCheckAvailability', function (e) {
		e.preventDefault ();
		var object_type = $(this).attr ('data-object-type');
		var uniq_id = $(this).attr ('data-uniq-id');

		$('#inline_' + object_type + '_' + uniq_id).parent('.do-check-availability').animate({
            height: "toggle",
            opacity: "toggle"
        }, 600);
    });

	$('body').on ('click', '.check_avail', function (e) {
		e.preventDefault ();
		var	form = $(this).closest ('form');
		var	that = $(this);
		var	wrapper = that.closest ('.avail_form');
		var	response = wrapper.find ('.availability_response');

		var	hotel_id = wrapper.find ('input[name="hotel_id"]').val ();
		var object_type = wrapper.find ('input[name="object_type"]').val ();
		var uniq_id = wrapper.find ('input[name="uniq_id"]').val ();

		var	check_in_date = wrapper.find ('input[name="check_in_date"]').val ();
		var	check_out_date = wrapper.find ('input[name="check_out_date"]').val ();
	
		var	booking_url = '/book/' + hotel_id + '/' + object_type + '/' + uniq_id + '?check_in_date=' + check_in_date + '&check_out_date=' + check_out_date;

		response.html ('<div class="loader"><img src="/img/loading.svg" alt="Checking..."/></div>');

		$.ajax ({
			type: 'GET',
			url: '/ajax/check_avail',
			data: form.serialize (),
			success: function (data) {
				response.html ('Great news! It is available in the selected period. <br> Please click: <a href="' + booking_url + '">here</a> to book');
			},

			error: function (data) {
				response.html ('Sadly, it is not available in the selected period!');
			}
		});
	});

});

/*
 * Starting Of BetterBooking hotels informations
 * 
 */
jQuery(document).ready(function(){
$('.display_bb_hotel').click (function (e) {
		var	transactionId = $(this).attr ('transactionId');
		var	searchId = $(this).attr ('searchId');
		var	stayId = $(this).attr ('stayId');
		var	full_url = '/ajax/render_bb_hotel?transactionId=' + transactionId + '&searchId=' + searchId + '&stayId=' + stayId;

		e.preventDefault ();
            $.ajax({
            type: 'GET',
            url: full_url,
            success:function(html){
                     
			$('#bb_hotel_modal').html (html);
                             $("#hotel_gallery").unitegallery ({
				gallery_background_color: "#fff",
				theme_enable_text_panel: false,

				theme_enable_fullscreen_button: true,
				theme_enable_play_button: false,
				theme_enable_hidepanel_button: false,

				gallery_width:580,
				gallery_height:400,

				slider_enable_zoom_panel: false,
				slider_videoplay_button_type: "round",
				thumb_width:175,
				thumb_height:100,

			     });
                         },
             error: function(xhr) { 
                 $('#bb_hotel_modal').modal ('hide');
             },
             beforeSend:function(){
             $('#bb_hotel_modal').modal ('show');
             }
                   });

		$.get (full_url, function (html) {
            
                        
		});
	});
        
        jQuery('.btn-check-av').click(function(){
                var	tomorrow = moment ().add (1, 'days');
                var	day_after = moment ().add (2, 'days');

                /* FR: Make sure we open the next day on the Check out date, once the Check in date has been changed. */

                $ ('.check_in_datepicker').datetimepicker ({
                        format: 'DD/MM/YYYY',
                        minDate: tomorrow,
                        defaultDate: tomorrow,
                }).on ('dp.change', function (e) {
                        var	check_out = $(this).closest ('.avail_form').find ('.check_out_datepicker');
                        var next_day = e.date.add (1, 'days');
                        check_out.data ('DateTimePicker').minDate (next_day).date (next_day);
                });

                $ ('.check_out_datepicker').datetimepicker ({
                        format: 'DD/MM/YYYY',
                        minDate: day_after,
                        defaultDate: day_after,
                });
        });
});
 $('#room_request,.room_request').click(function(e){
				e.preventDefault();
				$('input[name="room"]').val($(this).data('room'));
				$('#book-room-request').modal();
	    });






