/**
 * Created by Irina A. on 08.02.2017.
 */

function filterSlide(){
	//Freeze Start
	var scrolltop = $(this).scrollTop();
	if (scrolltop >= toffset) {
		$('.do-left-filter').addClass('do-freeze');
	}

	if (scrolltop < toffset) {
		$('.do-left-filter').removeClass('do-freeze');
	}

	//Bottom Stop
	var bottomstop = scrolltop + window.innerHeight - (window.innerHeight - fheight);
	if (bottomstop >= boffset) {
		$('.do-left-filter').addClass('do-stop');
	}

	else {
		$('.do-left-filter').removeClass('do-stop');
	}
}


$(document).ready(function(){
	//Left Filter Slide
	if($('.do-left-filter').length) {
		toffset = $('.do-left-filter').offset().top;
		fheight = $('.do-left-filter').height();
		boffset = $('.do-main-container').offset().top + $('.do-main-container').height();
		filterSlide();

		$(document).on('scroll', function () {
			filterSlide();
		});
	}

	//Menu Toggle
	$('#menu-toggle').on('click', function(){
		$('#main_menu').slideToggle()
	});

	// $('#main_menu ul li.parent > a').on('click',function(e){
	// 	e.preventDefault();
	// 	$(this).find('i').toggleClass('ion-chevron-right');
	// 	$(this).parent().toggleClass('open');
	// });

	//Sort Toggle
	$('#sort-toggle').on('click', function(){
		$('.do-sort-wrap').slideToggle()
	});

	//Filter Expand
	$('#filter-expand').on('click',function(){
		$(this).find('.icon').toggleClass('ion-android-arrow-dropup-circle');
		$(this).find('span').toggle();
		$('.do-row-filter ul li').slice(7).slideToggle();
	});

	//Left Filter Toggle
	$('.do-filter-toggle').on('click',function(){
		if(window.innerWidth <= 950){
			$('.do-left-filter').toggleClass('toggle');
		}
	});

	var	check_in_date = $('#check_in_date');
	var	check_out_date = $('#check_out_date');
	var	now = new Date ();

	if (check_in_date.length && check_out_date.length) {
		var	now = moment ();

		var	tomorrow = moment ().add (1, 'days');

		var	day_after = moment ().add (2, 'days');

		check_in_date.datetimepicker ({
			format: 'DD/MM/YYYY',
			minDate: tomorrow,
			defaultDate: tomorrow,
		});

		check_out_date.datetimepicker ({
			format: 'DD/MM/YYYY',
			minDate: day_after,
			defaultDate: day_after,
		});

		check_in_date.on ('dp.change', function (e) {
			var	next_day = e.date.add (1, 'days');

			check_out_date.data ('DateTimePicker').minDate (next_day).date (next_day);
		});
	}

	$(document).on('scroll',function(){
		if($(document).scrollTop() > 100 ){
			$('header .do-header-wrap:not(sticky)').addClass('sticky');
		}
		else if($(document).scrollTop() == 0){
			$('header .do-header-wrap.sticky').removeClass('sticky');
		}

	});

});





