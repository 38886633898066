$(function () {
	/* FR: Stupid two-way binding logic here... */
	$('.side_category').click (function (e) {
		e.preventDefault ();
		e.stopPropagation ();

		var	side_checkbox = $(this).find ('input');
		var search_icon_li = $('.form_search_icon[data-category-id="' + $(this).attr ('data-category-id') + '"]');
		search_icon_li.toggleClass ('active');

		var	hidden_checkbox = search_icon_li.find ('input[name="categories[]"]');

		if (hidden_checkbox.prop ('checked'))
			hidden_checkbox.prop ('checked', false);
		else
			hidden_checkbox.prop ('checked', 'checked');

		if (side_checkbox.prop ('checked'))
			side_checkbox.prop ('checked', false);
		else
			side_checkbox.prop ('checked', 'checked');

		$('#main_search_form').trigger ('submit');		
	});
//
//	$('.form_search_icon').on ('click', function (e) {
//		var myself = $(this);
//		var hidden_checkbox = myself.find ('input[name="categories[]"]');
//
//		e.preventDefault ();
//		e.stopPropagation ();
//
//		myself.toggleClass ('active');
//
//		if (hidden_checkbox.prop ('checked'))
//			hidden_checkbox.prop ('checked', false);
//		else
//			hidden_checkbox.prop ('checked', 'checked');
//
//		/* FR: If we need to toggle the appropriate checkbox on the left menu, we can do that too. XXX */
//		var	side_category = $('.side_category[data-category-id="' + hidden_checkbox.val () + '"]');
//		var	side_checkbox = side_category.find ('input');
//
//		console.log (side_checkbox);
//
//		if (side_checkbox.prop ('checked'))
//			side_checkbox.prop ('checked', false);
//		else
//			side_checkbox.prop ('checked', 'checked');
//
//
//		$('#main_search_form').trigger ('submit');		
//	});
});





